import React, { useState, useRef, useEffect } from "react";
import TodoList from "./TodoList";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

const LOCAL_STORAGE_KEY = "todoApp.todos";

function App() {
  const [todos, setTodos] = useState([]);
  const todoNameRef = useRef();

  useEffect(() => {
    //const storedTodos = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    //console.log(storedTodos);
    //const storedTodos = JSON.parse(axios.get('http://localhost:4000/getTodos'));
    const storedTodos = async () => {
      const resp = await axios.get(
        "https://react-todoapi.addisondubay.com/getTodos"
      );
      const respData = await resp.data[0].todos_JSON;
      const parseData = JSON.parse(respData);
      //return respData
      setTodos(parseData);
    };
    storedTodos();
    /*
        if (respData) {

          setTodos((respData));
        } */
  }, []);

  const setSQLData = async (updateSQL) => {
    const setSQL = await axios.post(
      "https://react-todoapi.addisondubay.com/updateTodos",
      {
        todos: JSON.stringify(updateSQL),
      }
    );
  };

  /*
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(todos));
    const setSQLData = async () => {
      const setSQL = await axios.post('http://localhost:4000/updateTodos', {
      todos: JSON.stringify(todos)
  
    })
  }

   
    
  }, [todos]);*/

  function toggleTodo(id) {
    const newTodos = [...todos];
    const todo = newTodos.find((todo) => todo.id === id);
    todo.complete = !todo.complete;
    setTodos(newTodos);
    setSQLData(newTodos);
  }

  function editTodo(id, rename) {
    const newTodos = [...todos];
    const todoIndex = newTodos.findIndex((todo) => todo.id === id);
    newTodos[todoIndex].name = rename;
    //todo.name = rename
    //added below .complete = !.complete (from toggleTodo) to fix onChange changing complete when editing todo.
    //todo.complete = !todo.complete
    setTodos(newTodos);
    setSQLData(newTodos);
  }

  function handleAddTodo(e) {
    const name = todoNameRef.current.value;
    if (name === "") return;
    setTodos((prevTodos) => {
      const addTodos = [
        ...prevTodos,
        {
          id: uuidv4(),
          name: name,
          complete: false,
        },
      ];
      setSQLData(addTodos);
      return addTodos;
    });

    todoNameRef.current.value = null;
  }

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      handleAddTodo();
    }
  }

  function handleClearTodos() {
    const newTodos = todos.filter((todo) => !todo.complete);
    setTodos(newTodos);
    setSQLData(newTodos);
  }

  return (
    <>
      <h1>Todo List</h1>
      <TodoList todos={todos} toggleTodo={toggleTodo} editTodo={editTodo} />
      <input ref={todoNameRef} type="text" onKeyDown={handleKeyDown} />
      <button onClick={handleAddTodo}>Add Todo</button>
      <button onClick={handleClearTodos}>Clear Completed</button>
      <p>{todos.filter((todo) => !todo.complete).length} left to do</p>
    </>
  );
}

export default App;
